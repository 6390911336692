import { airbrake } from '../entrypoints/airbrake'
import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['cardName', 'cardZip', 'cardAuthorized', 'submit', 'loading',
    'fields', 'field', 'validationError', 'unexpectedError', 'hiddenForm', 'hiddenPaymentToken', 'hiddenNumber',
    'hiddenName', 'hiddenBrand', 'hiddenPostal', 'hiddenExp']

  connect() {
    this.submitting = false
    this.configureConnectJS()
  }

  configureConnectJS() {
    CollectJS.configure({
         'fields': {
            'ccexp': {
                'placeholder': 'MM / YY'
            }
        },
        'customCss': {
          'background-color': '#dfe5e6',
          'padding': '0.375rem 0.75rem',
          'border-style': 'solid',
          'border-width': '1px',
          'border-color': '#ced4da',
          'border-radius': '6px',
          'height': 'calc(1.624em + 0.75rem + 2px)',
          'font-size': '0.813rem',
          'color': '#435363'
        },
        'invalidCss': {
          'border-color': '#dc3545'
        },
        'variant': 'inline',
        'callback' : function(response) {
            this.showLoading(true)
            this.fillHiddenForm(response)
            this.submitHiddenForm()
        }.bind(this),
        'fieldsAvailableCallback' : function() {
          this.displayAllFields()
        }.bind(this),
        'validationCallback' : function(_field, valid, _message) {
          if (!valid && this.submitting) {
            this.submitting = false
            this.showValidationErrors(true)
            this.enableSubmit(true)
          }
        }.bind(this)
    })
  }

  displayAllFields() {
    this.loadingTarget.classList.add('d-none')
    this.fieldTargets.forEach(function(field) {
      field.classList.remove('d-none')
    })
  }

  submit(event) {
    event.preventDefault()  // We do not submit the CC info to our server
    this.storeCard()
  }

  storeCard() {
    this.submitting = true
    this.hideErrors()
    this.enableSubmit(false)
    if (!this.valid()) {
      this.enableSubmit(true)
      return
    }
    CollectJS.startPaymentRequest()
  }

  submitHiddenForm() {
    let url = this.hiddenFormTarget.action
    let data = Rails.serializeElement(this.hiddenFormTarget)
    Rails.ajax({
      type: this.hiddenFormTarget.method,
      url: url,
      data: data,
      dataType: 'json',
      success: function(response) {
        if(response.status == 'success'){
          window.location.href = response.url
        }
        else {
          this.enableSubmit(true)
          this.showLoading(false)
          this.validationErrorTarget.innerText = response.error_message
          this.showValidationErrors(true)
        }
      }.bind(this),
      error: function(error) {
        this.enableSubmit(true)
        this.showLoading(false)
        this.showUnexpectedError(true)
      }.bind(this)
    })
  }

  // Place the token and the credit card info into the hidden form
  fillHiddenForm(response_data) {
    this.hiddenBrandTarget.value = response_data.card.type
    this.hiddenNumberTarget.value = response_data.card.number.slice(-4)
    this.hiddenPostalTarget.value = this.cardZipTarget.value
    this.hiddenExpTarget.value = response_data.card.exp
    this.hiddenNameTarget.value = this.cardNameTarget.value
    this.hiddenPaymentTokenTarget.value = response_data.token
  }

  showLoading(show) {
    if (show) {
      this.loadingTarget.classList.remove('d-none')
      this.fieldsTarget.classList.add('d-none')
    } else {
      this.loadingTarget.classList.add('d-none')
      this.fieldsTarget.classList.remove('d-none')
    }
  }

  showValidationErrors(show) {
    if (show) {
      this.validationErrorTarget.classList.remove('d-none')
      this.showLoading(false)
    } else {
      this.validationErrorTarget.classList.add('d-none')
    }
  }

  showUnexpectedError(show) {
    if (show) {
      this.unexpectedErrorTarget.classList.remove('d-none')
      this.showLoading(false)
    } else {
      this.unexpectedErrorTarget.classList.add('d-none')
    }
  }

  hideErrors() {
    this.showValidationErrors(false)
    this.showUnexpectedError(false)
  }

  enableSubmit(enable) {
    this.submitTarget.disabled = !enable
  }

  // Avoid short-circuiting here so we highlight all invalid fields
  // Validation of CC fields is done by CollectJS
  valid() {
    var valid = true
    if (!this.cardNameValid()) { valid = false }
    if (!this.cardZipValid()) { valid = false }
    if (!this.cardAuthorizedValid()) { valid = false }
    return valid
  }

  cardNameValid() {
    return this.fieldPresent(this.cardNameTarget)
  }

  cardZipValid() {
    return this.fieldPresent(this.cardZipTarget)
  }

  fieldPresent(field) {
    if (field.value.length > 0) {
      field.classList.remove('is-invalid')
      return true
    } else {
      field.classList.add('is-invalid')
      return false
    }
  }

  cardAuthorizedValid() {
    if (!this.hasCardAuthorizedTarget) { return true }

    return this.fieldChecked(this.cardAuthorizedTarget)
  }

  fieldChecked(field) {
    if (field.checked) {
      field.classList.remove('is-invalid')
      return true
    } else {
      field.classList.add('is-invalid')
      return false
    }
  }
}
