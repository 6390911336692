import { Controller } from 'stimulus'
import { airbrake } from '../entrypoints/airbrake'

export default class extends Controller {
  static targets = ['form', 'alert', 'submitButton', 'nameOnAccount', 'email', 'bankCountry',
                   'returnUrl', 'translatedGenericError']

  connect() {
    this.params = this.formTarget.dataset
    const key = this.params.publishableKey
    const stripe_account = this.params.stripeAccount
    this.stripe = Stripe(key, { stripeAccount: stripe_account })
  }

  submit(event) {
    event.preventDefault()
    this.show_loader()
    if(this.params.payNow) {
      this.redirection_method = this.stripe.confirmSofortPayment
    } else {
      this.redirection_method = this.stripe.confirmSofortSetup
    }
    this.redirect_to_sofort()
  }

  redirect_to_sofort() {
    this.redirection_method(this.formTarget.dataset.secret, {
      payment_method: {
        sofort: {
          country: this.bankCountryTarget.value
        },
        billing_details: {
          name: this.nameOnAccountTarget.value,
          email: this.emailTarget.value,
        }
      },
      return_url: this.returnUrlTarget.value
    })
    .then(function(result) {
      if (result.error){
        this.hide_loader()
        this.show_error(this.translatedGenericErrorTarget.textContent)
        this.sendAirbrake(result.error.message)
      }else {
        // The user will be automatically redirected by Stripe
      }
    }.bind(this))
  }


  sendAirbrake(err) {
    if(airbrake) {
      airbrake.notify({
        error: err,
        params: {
          name_on_account: this.nameOnAccountTarget.value,
          email: this.emailTarget.value,
          bankCountry: this.bankCountryTarget.value
        }
      })
    }else{
      console.log(err)
    }
  }

  show_loader() {
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.classList.add('submit-icon')
    this.submitButtonTarget.classList.add('loading')
  }

  hide_loader() {
    this.submitButtonTarget.disabled = false
    this.submitButtonTarget.classList.remove('submit-icon')
    this.submitButtonTarget.classList.remove('loading')
  }

  show_error(message) {
    this.alertTarget.classList.remove('d-none')
    this.alertTarget.textContent = message
  }

  hide_error() {
    this.alertTarget.classList.add('d-none')
    this.alertTarget.textContent = null
  }
}
