import { airbrake } from '../entrypoints/airbrake'
import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['form', 'alert', 'submitButton', 'intentObjectIdentifier', 'backButton', 'brandMessage']

  connect() {
    // See: # https://stripe.com/docs/stripe-js#elements
    this.params = this.formTarget.dataset
    const key = this.params.publishableKey
    const stripe_account = this.params.stripeAccount
    this.stripe = Stripe(key, { stripeAccount: stripe_account })
    this.elements = this.stripe.elements()  // Create an instance of Elements
    this.mountFields()
    this.handleFieldChange()
  }

  mountFields() {
    var style = JSON.parse(this.formTarget.dataset.inputStyle)

    this.cardNumber = this.elements.create('cardNumber', { style: style, placeholder: '', showIcon: true })
    let cardNumberReady = new Promise(function(resolve) {
      this.cardNumber.on('ready', function() { resolve() })
    }.bind(this))
    this.cardNumber.mount('#stripe-card-number-element')

    var placeholder = document.getElementById('stripe-card-expiry-element').getAttribute('data-placeholder')
    this.cardExpiry = this.elements.create('cardExpiry', { style: style, placeholder: placeholder })
    let cardExpiryReady = new Promise(function(resolve) {
      this.cardExpiry.on('ready', function() { resolve() })
    }.bind(this))
    this.cardExpiry.mount('#stripe-card-expiry-element')

    this.cardCvc = this.elements.create('cardCvc', { style: style, placeholder: '' })
    let cardCvcReady = new Promise(function(resolve) {
      this.cardCvc.on('ready', function() { resolve() })
    }.bind(this))
    this.cardCvc.mount('#stripe-card-cvc-element')

    if(this.params.payNow) {
      this.stripeConfirm = this.stripe.confirmCardPayment
    } else {
      this.stripeConfirm = this.stripe.confirmCardSetup
    }

    Promise.all([cardNumberReady, cardExpiryReady, cardCvcReady]).then(function(values) {
      // Once the Stripe fields are ready, enable the submit button
      this.hide_loader()
      this.cardNumber.focus()
    }.bind(this)).catch(function(error) {
      // If there's an unexpected error when mounting the fields, there's nothing we can do.
      // The payer must refresh the page.
      console.error(error)
      airbrake.notify({ error: error, params: { action: 'mountFields' } })
    }.bind(this))
  }

  handleFieldChange() {
    this.cardNumber.addEventListener('change', function(event) {
      this.alertTarget.classList.add('d-none')
    }.bind(this))
    this.cardExpiry.addEventListener('change', function(event) {
      this.alertTarget.classList.add('d-none')
    }.bind(this))
    this.cardCvc.addEventListener('change', function(event) {
      this.alertTarget.classList.add('d-none')
    }.bind(this))
    this.cardNumber.addEventListener('networkschange', function(event) {
      if (event.networks && event.networks.length >= 1 && event.networks.includes('cartes_bancaires')) {
        this.brandMessageTarget.classList.remove('d-none')
      }
    }.bind(this))
  }

  show_loader() {
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.classList.add('submit-icon')
    this.submitButtonTarget.classList.add('loading')
    this.backButtonTarget.classList.add('d-none')
  }

  hide_loader() {
    this.submitButtonTarget.disabled = false
    this.submitButtonTarget.classList.remove('submit-icon')
    this.submitButtonTarget.classList.remove('loading')
    this.backButtonTarget.classList.remove('d-none')
  }

  submit(event) {
    event.preventDefault()
    this.show_loader()
    this.stripe.createToken(this.cardNumber).then(function(result) {
      if (result.error) {
        this.show_error(result.error.message)
        this.hide_loader()
      } else {
        this.hide_error()
        this.stripeTokenHandler(result.token)
      }
    }.bind(this)).catch(function(error) {
      console.error(error)
      airbrake.notify({ error: error, params: { action: 'stripe.createToken' } })
      this.hide_loader()
    }.bind(this))
  }

  show_error(message) {
    this.alertTarget.classList.remove('d-none')
    this.alertTarget.textContent = message
  }

  hide_error() {
    this.alertTarget.classList.add('d-none')
    this.alertTarget.textContent = null
  }

  stripeTokenHandler(token) {
    this.card_token = token
    if (this.params.payNow) {
      this.confirmPayNow()
    }else {
      this.submitToStripe()
    }
  }

  submitToStripe() {
    this.stripeConfirm(this.formTarget.dataset.secret, {
      payment_method: {
        card: { token: this.card_token.id },
      }})
    .then(function(result) {
      if (result.error){
        if (this.params.payNow) {
          this.payNowFailed(result.error)
        }else {
          this.show_error(result.error.message)
          this.hide_loader()
        }
      }else {
        // It appears that sometime the payment is processed but the following code is not executed
        // Maybe because the payer closed its browser
        // In this case, the check event process should complete the payment when receiving the charge success event
        this.hide_error()
        this.stripeSetupIntentHandler(result)
      }
    }.bind(this))
  }

  stripeSetupIntentHandler(stripe_ajax_result) {
    const intentObject = stripe_ajax_result.setupIntent || stripe_ajax_result.paymentIntent
    // Insert setup_intent/payment_intent id into the form so it gets submitted to the server
    this.intentObjectIdentifierTarget.value = intentObject.id

    // Submit the form.  Note: This doesn't trigger a new submit event so we won't
    // re-enter the submit function above.
    this.formTarget.submit()
  }

  confirmPayNow(){
    Rails.ajax({
      type: 'POST',
      url: this.params.confirmPayNowPath,
      success: this.submitToStripe.bind(this),
      error: function(response) {
        this.show_error(response.error_message)
        this.hide_loader()
      }.bind(this)
    })
  }

  payNowFailed(response_error){
    let payment_intent_id = response_error.payment_intent.id
    if(response_error.code == 'payment_intent_unexpected_state' && response_error.payment_intent.status == 'succeeded') {
      this.hide_error()
      // In case the payer submitted the payment but we never had the redirection and user tried to pay again
      this.intentObjectIdentifierTarget.value = payment_intent_id
      this.formTarget.submit()
    }else {
      Rails.ajax({
        type: 'POST',
        data : new URLSearchParams({ payment_intent_id: payment_intent_id }).toString(),
        url: this.params.payNowFailedPath
      })
      this.show_error(response_error.message)
      this.hide_loader()
    }
  }
}
