import { airbrake } from '../entrypoints/airbrake'
import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
import DetectCardBrand from '../modules/detect_card_brand'

export default class extends Controller {
  static targets = ['entryForm', 'cardFunding', 'cardNumber', 'cardCvv', 'cardName', 'cardAddress',
    'cardZip', 'cardExpMonth', 'cardExpYear', 'cardAuthorized', 'submit', 'loading',
    'fields', 'validationError', 'unexpectedError', 'hiddenForm', 'hiddenNumber', 'hiddenCvv',
    'hiddenFirstName', 'hiddenLastName', 'hiddenAddress', 'hiddenPostal', 'hiddenExp', 'iframe']

  connect() {
    this.handleIframeLoad()

    let brands = JSON.parse(this.entryFormTarget.getAttribute('data-brands'))
    this.detectCardBrand = new DetectCardBrand(brands)
  }

  submit(event) {
    event.preventDefault()  // We do not submit the CC info to our server
    this.storeCard()
  }

  storeCard() {
    this.hideErrors()
    this.enableSubmit(false)
    if (!this.valid()) {
      this.enableSubmit(true)
      return
    }
    this.submitCardDataToNetworkMerchants()
  }

  // Request a new token from Network Merchants and create a NetworkMerchantsToken record
  createTokenAndSubmit() {
    var params = [
      this.buildParam('funding', this.cardFundingTarget.value),
      this.buildParam('brand', this.brand())
    ].join('&')
    Rails.ajax({
      type: 'POST',
      url: this.hiddenFormTarget.getAttribute('data-create-token-path'),
      dataType: 'json',
      data: params,
      async: false,  // Wait for a response
      success: function(json) {
        this.fillHiddenForm(json)
        this.hiddenFormTarget.submit()
      }.bind(this),
      error: function(error) {
        this.showValidationErrors(true)
        this.enableSubmit(true)
      }.bind(this)
    })
  }

  // Place the token and the credit card info into the hidden form
  fillHiddenForm(token_data) {
    this.hiddenFormTarget.setAttribute('action', token_data.form_url)

    this.hiddenNumberTarget.value = this.cardNumberTarget.value
    this.hiddenCvvTarget.value = this.cardCvvTarget.value
    this.hiddenAddressTarget.value = this.cardAddressTarget.value
    this.hiddenPostalTarget.value = this.cardZipTarget.value
    this.hiddenExpTarget.value = this.formattedExp()
    this.fillHiddenNameFields()
  }

  fillHiddenNameFields() {
    let parts = this.cardNameTarget.value.split(' ')
    if (parts.length == 1) {
      this.hiddenFirstNameTarget.value = parts[0]
    } else {
      let lastName = parts.pop()
      let firstName = parts.join(' ')
      this.hiddenFirstNameTarget.value = firstName
      this.hiddenLastNameTarget.value = lastName
    }
  }

  submitCardDataToNetworkMerchants() {
    this.showLoading(true)
    this.createTokenAndSubmit()
  }

  // When Network Merchants responds, the iframe will be asked to redirect to the store path.
  // That action will create the NetworkMerchantsCard record and then the "load" event here
  // will advance to the payment confirmation page.
  handleIframeLoad() {
    this.iframeTarget.addEventListener('load', function() {
      let validationInfo = {}
      let crossOriginError = false
      try {
        let iframeDoc = this.iframeTarget.contentDocument || this.iframeTarget.contentWindow.document
        let element = iframeDoc.querySelector('.credit_card_iframe_validation_info')

        // Firefox triggers a 'load' event for the iframe during the initial page load and again
        // when NetworkMerchantsCardsController#store passes up its info. There will be no validation
        // element present on the initial page load. Do nothing then.
        if (element) {
          validationInfo = element.getAttribute('data-validation-info')
          validationInfo = JSON.parse(validationInfo)
        }
      } catch(err) {
        // If the payment processor returns a response to the iframe instead of redirecting
        // to our store action, we won't be able to read the response and there is nothing
        // we can do. Display a generic error message and don't send an Airbrake.
        if (err.message.indexOf('cross-origin') != -1) {
          crossOriginError = true
        } else {
          this.sendAirbrake(err)
        }
      }
      if (validationInfo && validationInfo['status'] == 'success') {
        window.location.href = validationInfo['url']
      } else {
        this.enableSubmit(true)
        if (crossOriginError) {
          this.showUnexpectedError(true)
        } else if (validationInfo['error_message']) {
          this.validationErrorTarget.innerText = validationInfo['error_message']
          this.showValidationErrors(true)
        } else {
          this.hideErrors()
        }
        this.showLoading(false)
      }
    }.bind(this))
  }

  showLoading(show) {
    if (show) {
      this.loadingTarget.classList.remove('d-none')
      this.fieldsTarget.classList.add('d-none')
    } else {
      this.loadingTarget.classList.add('d-none')
      this.fieldsTarget.classList.remove('d-none')
    }
  }

  showValidationErrors(show) {
    if (show) {
      this.validationErrorTarget.classList.remove('d-none')
      this.showLoading(false)
    } else {
      this.validationErrorTarget.classList.add('d-none')
    }
  }

  showUnexpectedError(show) {
    if (show) {
      this.unexpectedErrorTarget.classList.remove('d-none')
      this.showLoading(false)
    } else {
      this.unexpectedErrorTarget.classList.add('d-none')
    }
  }

  hideErrors() {
    this.showValidationErrors(false)
    this.showUnexpectedError(false)
  }

  enableSubmit(enable) {
    this.submitTarget.disabled = !enable
  }

  // Format the expiration as MMYY
  formattedExp() {
    var exp_month = this.cardExpMonthTarget.value
    var exp_year = this.cardExpYearTarget.value
    exp_year = exp_year.substr(2, 2)
    return '' + exp_month + exp_year
  }

  brand() {
    return this.detectCardBrand.detect(this.cardNumberTarget.value)
  }

  // Avoid short-circuiting here so we highlight all invalid fields
  valid() {
    var valid = true
    if (!this.cardNumberValid()) { valid = false }
    if (!this.cardNameValid()) { valid = false }
    if (!this.cardCvvValid()) { valid = false }
    if (!this.cardAddressValid()) { valid = false }
    if (!this.cardZipValid()) { valid = false }
    if (!this.cardExpMonthValid()) { valid = false }
    if (!this.cardExpYearValid()) { valid = false }
    if (!this.cardAuthorizedValid()) { valid = false }
    return valid
  }

  cardNumberValid() {
    var number = this.cardNumberTarget.value
    if (number.length > 0 && number.match(/^\d+$/) != null && this.brand() != undefined) {
      this.cardNumberTarget.classList.remove('is-invalid')
      return true
    } else {
      this.cardNumberTarget.classList.add('is-invalid')
      return false
    }
  }

  cardCvvValid() {
    if (/^\d+$/.test(this.cardCvvTarget.value)) {
      this.cardCvvTarget.classList.remove('is-invalid')
      return true
    } else {
      this.cardCvvTarget.classList.add('is-invalid')
      return false
    }
  }

  cardNameValid() {
    return this.fieldPresent(this.cardNameTarget)
  }

  cardAddressValid() {
    return this.fieldPresent(this.cardAddressTarget)
  }

  cardZipValid() {
    return this.fieldPresent(this.cardZipTarget)
  }

  cardExpMonthValid() {
    return this.fieldPresent(this.cardExpMonthTarget)
  }

  cardExpYearValid() {
    return this.fieldPresent(this.cardExpYearTarget)
  }

  fieldPresent(field) {
    if (field.value.length > 0) {
      field.classList.remove('is-invalid')
      return true
    } else {
      field.classList.add('is-invalid')
      return false
    }
  }

  cardAuthorizedValid() {
    if (!this.hasCardAuthorizedTarget) { return true }

    return this.fieldChecked(this.cardAuthorizedTarget)
  }

  fieldChecked(field) {
    if (field.checked) {
      field.classList.remove('is-invalid')
      return true
    } else {
      field.classList.add('is-invalid')
      return false
    }
  }

  sendAirbrake(err) {
    if (!airbrake) { return }

    airbrake.notify({
      error: err,
      params: { form_fields: this.formData() }
    })
  }

  formData() {
    var data = []
    var inputs = this.hiddenFormTarget.querySelectorAll('input')
    for (var i = 0; i < inputs.length; i++) {
      var input = inputs[i]
      var value = input.id == 'billing-cc-number' || input.id == 'billing-cvv' ? 'REMOVED' : input.value
      data.push({ name: input.id, value: value })
    }
    return data
  }

  buildParam(name, value) {
    return name + '=' + encodeURIComponent(value)
  }
}
