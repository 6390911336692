import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['alert', 'cenposIframe', 'form', 'customerCode', 'setupIntentToken', 'submitButton', 'submittedCardInfo', 'loading']

  connect() {
    this.cenposJQueryPlugin = $(this.cenposIframeTarget)
    this.initIframe()
  }

  initIframe() {
    const params = {
      customerCode: this.customerCodeTarget.value,
      customUrlCss: this.formTarget.dataset.cssStyleSheetUrl,
      verifyingpost: this.setupIntentTokenTarget.value,
      isCvv: true,
    }

    let url_params = ''
    for(let key in params) {
      url_params += key + '=' + encodeURIComponent(params[key]) + '&'
    }

    this.cenposJQueryPlugin.createWebpay({
      url: 'https://www.cenpos.net/simplewebpay/cards/',
      params: url_params,
      height: '280px',
      success: function(responseData) { this.submitSuccess(responseData) }.bind(this),
      cancel: function(responseData) { this.submitCancel(responseData) }.bind(this)
    })
  }

  submit(event){
    event.preventDefault()
    this.hideError()
    this.cenposJQueryPlugin.submitAction()
    this.showLoading()
  }

  submitSuccess(responseData){
    this.submittedCardInfoTarget.value = JSON.stringify(responseData)
    this.formTarget.submit()
  }

  submitCancel(responseData){
    if(responseData && responseData != 'Error in Form') {
      this.notifyError(responseData)
      // To regenerate a new token because it has been consumed by Cenpos
      // Other requests with the same token will return "Unauthorized"
      let reload_url = window.location.href
      if(reload_url.indexOf('card_error=') === -1) {
        reload_url += '&card_error=true'
      }
      window.location.href = reload_url
      return
    }
    this.hideLoading()
    this.showError()
  }

  showError() {
    this.alertTarget.classList.remove('d-none')
  }

  hideError() {
    this.alertTarget.classList.add('d-none')
  }

  showLoading() {
    this.submitButtonTarget.disabled = true
    this.loadingTarget.classList.remove('d-none')
    this.cenposIframeTarget.style.visibility = 'hidden'
  }

  hideLoading() {
    this.submitButtonTarget.disabled = false
    this.loadingTarget.classList.add('d-none')
    this.cenposIframeTarget.style.visibility = 'visible'
  }

  notifyError(responseData) {
    if(!airbrake) return
    if(responseData['Result'] == 6) return // Don't log CVV Errors

    if(typeof(responseData) === 'object') {
      responseData = JSON.stringify(responseData)
    }
    airbrake.notify({
      error: 'CenPOS Card registration error',
      params: {
        responseData: responseData,
        customerCode: this.customerCodeTarget.value,
        verifyingpost: this.setupIntentTokenTarget.value
      }
    })
  }
}
