import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('input, select, textarea').forEach((input) => {
      if (input.classList.contains('is-invalid')) {
        input.addEventListener('input', () => {
          input.classList.remove('is-invalid')
          input.parentElement.querySelector('.invalid-tooltip').classList.add('d-none')
        })
      }
    })
  }
}
