import { airbrake } from '../entrypoints/airbrake'
import Rails from '@rails/ujs'
import { Modal } from 'bootstrap'
import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['entryForm', 'cardNumber', 'cardType', 'transientJwtToken',
    'cardExpMonth', 'cardExpYear', 'cardAuthorized', 'submit', 'loading',
    'fields', 'validationError', 'stateContainer',
    'payerAuthentSetupPlaceHolder', 'checkEnrollmentPlaceHolder', 'authenticated',
    'cardFirstName', 'cardLastName', 'cardAddress', 'cardCity', 'cardPostalCode', 'cardCountry', 'cardState', 'cardPhoneNumber',
    'commerceIndicator', 'ucafCollectionIndicator', 'cavv', 'ucafAuthenticationData', 'xid', 'veresEnrolled',
    'specificationVersion', 'directoryServerTransactionId'
  ]

  connect() {
    this.init_cybersource_microform()
    this.reloadPageBeforeTokenExpire()
  }

  reloadPageBeforeTokenExpire() {
    // CyberSource session token expires in 15min
    // Reload the page at 14 min to generate a new token
    const fourteen_minutes_in_ms = 14 * 60 * 1000
    setTimeout(()=> {
      window.location.reload()
    }, fourteen_minutes_in_ms)
  }

  init_cybersource_microform() {
    const custom_style = {
      'input': {
        'color': '#435363',
        'font-family': '"Roboto", sans-serif',
        'font-size': '13px'
      },
      'invalid': {
         'color': '#ca3838'
      }
    }

    const captureContext_key = this.data.get('capture_context_key')
    this.microform = new Flex(captureContext_key).microform({ styles: custom_style })
    this.microform.createField('number', {}).load('#number-container')
    this.microform.createField('securityCode', {}).load('#securityCode-container')
  }

  submit(event) {
    event.preventDefault()
    this.storeCard()
  }

  storeCard() {
    this.showValidationErrors(false)
    this.enableSubmit(false)
    if (!this.valid()) {
      this.enableSubmit(true)
      return
    }
    this.showLoading(true)
    this.generateCardToken()
  }

  generateCardToken() {
    const nonSensitiveData = {
      expirationMonth: this.expMonth(),
      expirationYear: this.expYear()
    }

    this.microform.createToken(nonSensitiveData, function(microform_error, jwtToken){
      if(microform_error) {
        this.handle_microform_validation_error(microform_error)
      }else {
        this.handleGenerateCardTokenResponse(jwtToken)
      }
    }.bind(this))
  }

  handle_microform_validation_error(microform_error) {
    switch(microform_error.reason) {
      case 'CREATE_TOKEN_VALIDATION_FIELDS':
        if(microform_error.details == null) {
          this.showValidationErrors(true)
        } else {
          this.setErrorDetails(microform_error.details)
        }
        break;
      default:
        // For errors we don't know how to handle, throw an airbrake
        this.notifyError(microform_error)
        this.showValidationErrors(true)
        break;
    }

    this.reset_form()
  }

  setErrorDetails(details) {
    details.forEach(detail => {
      if(detail.message === 'Validation error') {
        document.getElementById(detail.location + '-container').classList.add('is-invalid')
      } else {
        this.showValidationErrors(true)
      }
    })
  }

  handleGenerateCardTokenResponse(jwtToken) {
    this.transientJwtTokenTarget.value = jwtToken
    const parsedJwt = this.parseJwt(jwtToken)
    this.cardToken = parsedJwt.jti
    const cardInfo =  parsedJwt.content.paymentInformation.card
    this.cardNumberTarget.value = cardInfo.number.maskedValue.slice(-4)
    this.cardTypeTarget.value = cardInfo.type.value
    if (this.data.get('payer_authentication') == "true") {
      this.init_payer_authentication()
    } else {
      this.sendTokenizedCard()
    }
  }

  init_payer_authentication() {
    const data = { card_token: this.cardToken }
    Rails.ajax({
      type: 'GET',
      url: this.data.get('request-payer-auth-setup-path'),
      dataType: 'json',
      data: new URLSearchParams(data).toString(),
      async: false,
      success: this.handleInitPayerAuthenticationResponse.bind(this),
      error: function(error) {
        this.showValidationErrors(true)
      }.bind(this)
    })
  }

  handleInitPayerAuthenticationResponse(response) {
    if (!response.cybersource_configured_for_3d_secure) {
      return this.sendTokenizedCard()
    }

    // Listen the for the event indicating Cruise Control client is loaded (Songbird.js)
    window.addEventListener("message", this.handleSetupCompletion.bind(this), false)

    this.auth_reference_id = response.auth_reference_id
    this.payerAuthentSetupPlaceHolderTarget.innerHTML = response.cardinal_cruise_init_form

    // Submit the form
    this.payerAuthentSetupPlaceHolderTarget.querySelector('form').submit()
  }

  handleSetupCompletion(event) {
    if (event.origin.indexOf('.cardinalcommerce.com') <= 0) return // The event must come from Cardinal Commerce

    let data = JSON.parse(event.data)
    if (data && data.Status) {
      this.checkEnrollment()
    }else {
      airbrake.notify({
        error: 'CyberSource failed to load Songbird.js (Cardinal Cruise Library)',
         params: data
      })
      this.showValidationErrors(true)
    }
  }

  checkEnrollment(){
    let data = {
      card_token: this.cardToken,
      auth_reference_id: this.auth_reference_id
    }
    Rails.ajax({
      type: 'GET',
      url: this.data.get('request-check-enrollment-path'),
      dataType: 'json',
      async: false,
      data: new URLSearchParams(data).toString(),
      success: this.handleCheckEnrollmentResponse.bind(this),
      error: function(error) {
        this.showValidationErrors(true)
      }.bind(this)
    })
  }

  handleCheckEnrollmentResponse(response){
    this.commerceIndicatorTarget.value = response.commerce_indicator
    this.ucafCollectionIndicatorTarget.value = response.ucaf_collection_indicator
    this.cavvTarget.value = response.cavv
    this.ucafAuthenticationDataTarget.value = response.ucaf_authentication_data
    this.xidTarget.value = response.xid
    this.veresEnrolledTarget.value = response.veres_enrolled
    this.specificationVersionTarget.value = response.specification_version
    this.directoryServerTransactionIdTarget.value = response.directory_server_transaction_id

    switch(response.authentication_status) {
      case 'PENDING_AUTHENTICATION':
        this.init_3d_secure_form(response.authent_form_3d_secure)
        break;
      case 'AUTHENTICATION_FAILED':
      case 'CONSUMER_AUTHENTICATION_FAILED':
        this.showValidationErrors(true)
        break;
      case 'AUTHENTICATION_SUCCESSFUL':
      case 'AUTHENTICATION_NOT_AVAILABLE':
        this.sendTokenizedCard()
        break;
      default:
        this.showValidationErrors(true)
        throw "Unexpected authentication status: " + response.authentication_status
    }
  }

  init_3d_secure_form(html_form) {
    // Listen for the event indicating the payer completed the authentication
    window.addEventListener("3d_secure_completed", this.handle3dSecureCompletion.bind(this), false)

    this.checkEnrollmentPlaceHolderTarget.querySelector('.modal-content').innerHTML = html_form
    this.modal = new Modal(this.checkEnrollmentPlaceHolderTarget, {
      keyboard: false,
      backdrop: 'static'
    })

    // Start to show the blank modal before we submit the form so that it's easier to
    // see that we've started the 3D Secure flow when debugging problems.
    this.modal.show()

    // If there's a form, submit it.  Otherwise, display the modal with error info.
    let form = this.checkEnrollmentPlaceHolderTarget.querySelector('form')
    if (form) { form.submit() }
  }

  handle3dSecureCompletion(event) {
    let data = {
      card_token: this.cardToken,
      transaction_id: event.detail.transaction_id
    }
    Rails.ajax({
      type: 'GET',
      url: this.data.get('request-validate-auth-results-path'),
      dataType: 'json',
      async: false,
      data: new URLSearchParams(data).toString(),
      success: this.handleValidateAuthResults.bind(this),
      error: function(error) {
        this.showValidationErrors(true)
      }.bind(this)
    })
  }

  handleValidateAuthResults(response) {
    if (response.authent_success) {
      this.authenticatedTarget.value = true
      this.commerceIndicatorTarget.value = response.commerce_indicator
      this.ucafCollectionIndicatorTarget.value = response.ucaf_collection_indicator
      this.cavvTarget.value = response.cavv
      this.ucafAuthenticationDataTarget.value = response.ucaf_authentication_data
      this.xidTarget.value = response.xid
      this.specificationVersionTarget.value = response.specification_version
      this.directoryServerTransactionIdTarget.value = response.directory_server_transaction_id
      this.sendTokenizedCard()
    } else {
      this.showValidationErrors(true)
    }
  }

  sendTokenizedCard() {
    this.entryFormTarget.submit()
  }

  showLoading(show) {
    if (show) {
      let flash_message = document.querySelector('.alert-dismissible')
      if (flash_message) {
        flash_message.style.display = 'none'
      }
      this.loadingTarget.classList.remove('d-none')
      this.fieldsTarget.classList.add('d-none')
    } else {
      this.loadingTarget.classList.add('d-none')
      this.fieldsTarget.classList.remove('d-none')
    }
  }

  showValidationErrors(show) {
    if (show) {
      this.reset_form()
      this.validationErrorTarget.classList.remove('d-none')
    } else {
      this.validationErrorTarget.classList.add('d-none')
    }
  }

  reset_form() {
    if(this.modal) {
      this.modal.hide()
    }
    this.submitTarget.disabled = false
    this.showLoading(false)
  }

  enableSubmit(enable) {
    this.submitTarget.disabled = !enable
  }

  expMonth() {
    return this.cardExpMonthTarget.value
  }

  expYear() {
    return this.cardExpYearTarget.value
  }

  // Avoid short-circuiting here so we highlight all invalid fields
  valid() {
    let valid = true
    if (!this.cardFirstNameValid()) { valid = false }
    if (!this.cardLastNameValid()) { valid = false }
    if (!this.cardAddressValid()) { valid = false }
    if (!this.cardCityValid()) { valid = false }
    if (!this.cardPostalCodeValid()) { valid = false }
    if (!this.cardCountryValid()) { valid = false }
    if (!this.cardStateValid()) { valid = false }
    if (!this.cardPhoneNumberValid()) { valid = false }
    if (!this.cardExpMonthValid()) { valid = false }
    if (!this.cardExpYearValid()) { valid = false }
    if (!this.cardAuthorizedValid()) { valid = false }
    return valid
  }

  cardFirstNameValid() {
    return this.fieldPresent(this.cardFirstNameTarget)
  }

  cardLastNameValid() {
    return this.fieldPresent(this.cardLastNameTarget)
  }

  cardAddressValid() {
    return this.fieldPresent(this.cardAddressTarget)
  }

  cardCityValid() {
    return this.fieldPresent(this.cardCityTarget)
  }

  cardPostalCodeValid() {
    return this.fieldPresent(this.cardPostalCodeTarget)
  }

  cardCountryValid() {
    return this.fieldPresent(this.cardCountryTarget)
  }

  cardStateValid() {
    if(['US', 'CA'].includes(this.cardCountryTarget.value)) {
      return this.fieldPresent(this.cardStateTarget)
    }else {
      return true
    }
  }

  cardPhoneNumberValid() {
    return !this.hasCardPhoneNumberTarget || this.fieldPresent(this.cardPhoneNumberTarget)
  }

  cardExpMonthValid() {
    return this.fieldPresent(this.cardExpMonthTarget)
  }

  cardExpYearValid() {
    return this.fieldPresent(this.cardExpYearTarget)
  }

  fieldPresent(field) {
    if (field.value.length > 0) {
      field.classList.remove('is-invalid')
      return true
    } else {
      field.classList.add('is-invalid')
      return false
    }
  }

  cardAuthorizedValid() {
    if (!this.hasCardAuthorizedTarget) { return true }

    return this.fieldChecked(this.cardAuthorizedTarget)
  }

  fieldChecked(field) {
    if (field.checked) {
      field.classList.remove('is-invalid')
      return true
    } else {
      field.classList.add('is-invalid')
      return false
    }
  }

  dismissErrorModal() {
    this.reset_form()
  }

  parseJwt(token) {
    return JSON.parse(atob(token.split('.')[1]))
  }

  notifyError(microform_error) {
    if(!airbrake) { return }

    let details = ""
    if (typeof(microform_error.details) === 'object') {
      details = JSON.stringify(microform_error.details)
    }

    airbrake.notify({
      error: 'CyberSource Card Registration Error',
      params: {
        reason: microform_error.reason,
        message: microform_error.message,
        details: details,
        correlationId: microform_error.correlationId
      }
    })
  }
}
