import { Controller } from 'stimulus'

export default class extends Controller {
  acss(event) {
    event.preventDefault()
    let line = event.currentTarget.closest('tr')
    document.getElementById('stripe_acss_mandate_institution_number').value = line.children[1].textContent
    document.getElementById('stripe_acss_mandate_transit_number').value = line.children[2].textContent
    document.getElementById('stripe_acss_mandate_account_number').value = line.children[3].textContent
  }

  networkMerchants(event) {
    event.preventDefault()
    document.getElementById('network_merchants_card_full_card_number').value = event.currentTarget.innerText
    document.getElementById('network_merchants_card_cvv').value = '123'
    document.getElementById('network_merchants_card_card_name').value = this.defaultName()
    document.getElementById('network_merchants_card_credit_card_address').value = this.defaultAddress()
    document.getElementById('network_merchants_card_credit_card_zip').value = this.defaultCanadianPostalCode()
    document.getElementById('network_merchants_card_exp_month').value = this.defaultExpMonth()
    document.getElementById('network_merchants_card_exp_year').value = this.defaultExpYear()
  }

  sofort(event) {
    event.preventDefault()
    document.getElementById('stripe_sepa_mandate_email').value = event.currentTarget.parentElement.dataset.email
  }

  stripeAch(event) {
    event.preventDefault()

    let routingNumber = this.element.getAttribute('data-payment-method-autofill-routing-number')
    let accountNumber = this.element.getAttribute('data-payment-method-autofill-account-number')

    document.getElementById('stripe_ach_account_name_on_account').value = this.defaultName()
    document.getElementById('stripe_ach_account_routing_number').value = routingNumber
    document.getElementById('stripe_ach_account_full_account_number').value = accountNumber
    document.getElementById('stripe_ach_account_full_account_number_confirmation').value = accountNumber
    let checkboxIds = [
      'stripe_ach_account_authorized',
      'stripe_ach_account_authorize_accuracy_statement_agreed'
    ]
    checkboxIds.forEach((checkboxId) => {
      let checkbox = document.getElementById(checkboxId)
      if (checkbox) { checkbox.checked = true }
    })
    let signature = document.getElementById('stripe_ach_account_signature')
    if (signature) { signature.value = this.defaultName() }
  }

  stripeAchVerification(event) {
    event.preventDefault()

    let amount1 = this.element.getAttribute('data-payment-method-autofill-amount1')
    let amount2 = this.element.getAttribute('data-payment-method-autofill-amount2')

    document.getElementById('stripe_ach_verification_attempt_amount1').value = amount1
    document.getElementById('stripe_ach_verification_attempt_amount2').value = amount2
  }

  trustCommerce(event) {
    event.preventDefault()
    document.getElementById('trust_commerce_card_full_card_number').value = event.currentTarget.innerText
    document.getElementById('trust_commerce_card_cvv').value = '123'
    document.getElementById('trust_commerce_card_card_name').value = this.defaultName()
    document.getElementById('trust_commerce_card_credit_card_address').value = this.defaultAddress()
    document.getElementById('trust_commerce_card_credit_card_zip').value = this.defaultUnitedStatesPostalCode()
    document.getElementById('trust_commerce_card_exp_month').value = this.defaultExpMonth()
    document.getElementById('trust_commerce_card_exp_year').value = this.defaultExpYear()
  }

  payrixCard(event) {
    event.preventDefault()
    document.getElementById('payrix_card_number').value = event.currentTarget.innerText
    document.getElementById('payrix_card_name_on_card').value = this.defaultName()
    document.getElementById('payrix_card_exp_month').value = this.defaultExpMonth()
    document.getElementById('payrix_card_exp_year').value = this.defaultExpYear()
    document.getElementById('payrix_card_cvv').value = this.defaultCvv()
  }

  payrixBecs(event) {
    event.preventDefault()
    document.getElementById('payrix_becs_mandate_name_on_account').value = this.defaultName()
    document.getElementById('payrix_becs_mandate_branch').value = '082-082'
    document.getElementById('payrix_becs_mandate_number').value = '012345678'
  }

  cyberSource(event) {
    event.preventDefault()
    document.getElementById('cyber_source_card_first_name').value = this.defaultFirstName()
    document.getElementById('cyber_source_card_last_name').value = this.defaultLastName()
    document.getElementById('cyber_source_card_address').value = this.defaultAddress()
    document.getElementById('cyber_source_card_postal_code').value = this.defaultUnitedStatesPostalCode()
    document.getElementById('cyber_source_card_city').value = this.defaultUnitedStatesCity()
    let country = document.getElementById('cyber_source_card_country')
    country.value = this.defaultCountry()
    country.dispatchEvent(new Event('change'))
    document.getElementById('cyber_source_card_state').value = this.defaultUnitedStatesState()
    let phone_number_field = document.getElementById('cyber_source_card_phone_number')
    if(phone_number_field) { phone_number_field.value = this.defaultUnitedStatesPhoneNumber() }
  }

  paytraceCard(event) {
    event.preventDefault()
    document.getElementById('paytrace_card_name_on_card').value = this.defaultFirstName()
    document.getElementById('paytrace_card_street_address1').value = this.defaultAddress()
    document.getElementById('paytrace_card_postal_code').value = this.defaultUnitedStatesPostalCode()
    document.getElementById('paytrace_card_city').value = this.defaultUnitedStatesCity()
    document.getElementById('paytrace_card_state').value = this.defaultUnitedStatesState()
  }

  jetpayBankAccount(event) {
    event.preventDefault()
    document.getElementById('jetpay_bank_account_transit_number').value = 77777
    document.getElementById('jetpay_bank_account_institution_number').value = 777
    document.getElementById('jetpay_bank_account_full_account_number').value = 777777777777
    document.getElementById('jetpay_bank_account_account_title').value = 'Primary bank account'
    document.getElementById('jetpay_bank_account_institution_name').value = this.defaultCanadianBankName()
    document.getElementById('jetpay_bank_account_holder_name').value = this.defaultName()
    document.getElementById('jetpay_bank_account_holder_email').value =  'payer' + new Date().getTime() + '@customer.com'
    document.getElementById('jetpay_bank_account_holder_address').value = this.defaultAddress()
    document.getElementById('jetpay_bank_account_holder_address_postal_code').value = this.defaultCanadianPostalCode()
    document.getElementById('jetpay_bank_account_holder_address_city').value = this.defaultCanadianCity()
  }

  defaultName() {
    return 'Test Person'
  }

  defaultFirstName() {
    return 'Payer-First-Name'
  }

  defaultLastName() {
    return 'Payer-Last-Name'
  }

  defaultAddress() {
    return '123 Elm St.'
  }

  defaultCountry() {
    return 'US'
  }

  defaultUnitedStatesState() {
    return 'WI'
  }

  defaultUnitedStatesPostalCode() {
    return '53562'
  }

  defaultUnitedStatesCity() {
    return 'Madison'
  }

  defaultUnitedStatesPhoneNumber() {
    return '650 965 6000'
  }

  defaultExpMonth() {
    return '04'
  }

  defaultExpYear() {
    return (new Date()).getUTCFullYear() + 1
  }

  defaultCvv() {
    return '123'
  }

  defaultCanadianBankName() {
    return 'RBC Royal Bank'
  }

  defaultCanadianCity() {
    return 'Montreal'
  }

  defaultCanadianPostalCode() {
    return 'H2Y 4B2'
  }
}
