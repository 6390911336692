import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'alert', 'submitButton', 'amount1', 'amount2', 'errorCode', 'verified']

  connect() {
    const key = this.formTarget.dataset.publishableKey
    const stripe_account = this.formTarget.dataset.stripeAccount
    this.stripe = Stripe(key, { stripeAccount: stripe_account, betas: ['acss_debit_beta_1'] }) 
    this.retryErrorCodes = ["payment_method_microdeposit_verification_amounts_mismatch", "payment_method_microdeposit_verification_amounts_invalid"]
  }

  submit(event) {
    event.preventDefault()
    this.show_loader()
    this.stripe.verifyMicrodepositsForSetup(this.formTarget.dataset.secret, {
      amounts: [this.amount1Target.value, this.amount2Target.value],
    })
    .then(function(result) {
      if (result.error){
        this.handle_verification_error(result.error)
      }else {
        this.submit_account_verified()
      }
    }.bind(this))
  }

  handle_verification_error(error) {
    if(this.retryErrorCodes.includes(error.code)) {
      this.show_error(error.message)
      this.hide_loader()
    }else{
      this.submit_final_error(error.code)
    }
  }

  submit_final_error(error_code) {
    this.errorCodeTarget.value = error_code
    this.verifiedTarget.value = false
    this.formTarget.submit()
  }

  submit_account_verified() {
    this.verifiedTarget.value = true
    this.formTarget.submit()
  }

  show_error(message) {
    this.alertTarget.classList.remove('d-none')
    this.alertTarget.textContent = message
  }

  hide_error() {
    this.alertTarget.classList.add('d-none')
    this.alertTarget.textContent = null
  }

  show_loader() {
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.classList.add('submit-icon')
    this.submitButtonTarget.classList.add('loading')
  }

  hide_loader() {
    this.submitButtonTarget.disabled = false
    this.submitButtonTarget.classList.remove('submit-icon')
    this.submitButtonTarget.classList.remove('loading')
  }
}
