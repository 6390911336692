import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['info', 'form']

  editKeys(event) {
    event.preventDefault()
    this.toggleForm(true)
  }

  cancel(event) {
    event.preventDefault()
    this.toggleForm(false)
  }

  toggleForm(show) {
    if (show) {
      this.infoTarget.classList.add('d-none')
      this.formTarget.classList.remove('d-none')
    } else {
      this.infoTarget.classList.remove('d-none')
      this.formTarget.classList.add('d-none')
    }
  }
}
