import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['existingPayer', 'newPayer', 'payerId', 'payerFirstName', 'payerLastName', 'payerCompanyName']

  payerOptionChanged() {
    var showExisting = document.querySelector('input[name=payer_option]:checked').value == 'existing'
    this.showExistingPayer(showExisting)
  }

  showExistingPayer(show) {
    if (show) {
      this.newPayerTarget.classList.add('d-none')
      this.existingPayerTarget.classList.remove('d-none')
    } else {
      this.existingPayerTarget.classList.add('d-none')
      this.newPayerTarget.classList.remove('d-none')
    }
    this.payerIdTarget.disabled = !show
    this.payerFirstNameTarget.disabled = show
    this.payerLastNameTarget.disabled = show
    this.payerCompanyNameTarget.disabled = show
  }
}
