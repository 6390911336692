import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['billingAddress', 'billingCountry']

  connect() {
    this.application[this.identifier] = this
    this.parent = this.application['stripe-mandates']
    this.stripe = this.parent.stripe
    this.options = this.parent.formTarget.dataset
    this.elements = this.parent.elements
    this.mountFields()
  }

  mountFields() {
    const options = {
      style: JSON.parse(this.options.inputStyle),
      supportedCountries: ['SEPA'],
      placeholderCountry: this.options.countryCode
    }
    this.ibanTarget = this.elements.create('iban', options);
    this.ibanTarget.mount('#iban-element');
  }

  confirm_mandate_in_stripe() {
     return this.stripe.confirmSepaDebitSetup(
      this.options.secret,
      {
        payment_method: {
          sepa_debit: this.ibanTarget,
          billing_details: {
            name: this.parent.nameOnAccountTarget.value,
            email: this.parent.emailTarget.value,
            address: {
              line1: this.billingAddressTarget.value,
              country: this.billingCountryTarget.value,
            },
          },
        },
      }
    )
  }
}
