import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import { replaceWith } from '../modules/replace_with'

export default class extends Controller {
  static targets = ['container']

  refresh() {
    Rails.ajax({
      type: 'GET',
      url: this.containerTarget.getAttribute('data-url'),
      dataType: 'json',
      success: function(data) {
        replaceWith(this.containerTarget, data['html'])
      }.bind(this),
      error: function(error) {
        replaceWith(this.containerTarget, 'An error has occurred.')
      }.bind(this)
    })
  }
}
