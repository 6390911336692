import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'alert', 'submitButton', 'nameOnAccount', 'email']

  connect() {
    this.application[this.identifier] = this
    // See: # https://stripe.com/docs/stripe-js#elements
    const key = this.formTarget.dataset.publishableKey
    const stripe_account = this.formTarget.dataset.stripeAccount
    this.stripe = Stripe(key, { stripeAccount: stripe_account, betas: ['acss_debit_beta_1'] })  // Create Stripe client
    this.elements = this.stripe.elements()  // Create an instance of Elements
  }

  confirm_mandate_in_stripe() {
    const child = this.application['stripe-' + this.formTarget.dataset.debitType + '-mandates']
    return child.confirm_mandate_in_stripe()
  }

  submit(event) {
    event.preventDefault()
    this.show_loader()
    this.confirm_mandate_in_stripe().then(function(result) {
      if (result.error){
        if(result.code === 'parameter_invalid_empty'){
          this.show_error(document.getElementById('translated-generic-error').textContent)
        }else {
          this.show_error(result.error.message)
        }
        this.hide_loader()
      }else {
        this.stripeSetupIntentHandler(result.setupIntent)
      }
    }.bind(this))
  }

  show_error(message) {
    this.alertTarget.classList.remove('d-none')
    this.alertTarget.textContent = message
  }

  hide_error() {
    this.alertTarget.classList.add('d-none')
    this.alertTarget.textContent = null
  }

  show_loader() {
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.classList.add('submit-icon')
    this.submitButtonTarget.classList.add('loading')
  }

  hide_loader() {
    this.submitButtonTarget.disabled = false
    this.submitButtonTarget.classList.remove('submit-icon')
    this.submitButtonTarget.classList.remove('loading')
  }

  stripeSetupIntentHandler(setupIntent) {
    // Submit the form.  Note: This doesn't trigger a new submit event so we won't
    // re-enter the submit function above.
    this.formTarget.submit()
  }
}
