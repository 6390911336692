import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['bankName']

  connect() {
    this.application[this.identifier] = this
    this.parent = this.application['stripe-mandates']
    this.stripe = this.parent.stripe
    this.options = this.parent.formTarget.dataset
    this.elements = this.parent.elements
    this.mountFields()
  }

  mountFields() {
    const options = {
      style: JSON.parse(this.options.inputStyle),
      disabled: false,
      hideIcon: false,
      iconStyle: "default" // or "solid"
    }
    this.bankAccountTarget = this.elements.create('auBankAccount', options);
    this.bankAccountTarget.mount('#bank-account-element');

    const controller = this
    this.bankAccountTarget.on('change', function(event) {
      if (event.error) {
        controller.bankNameTarget.textContent = null
        controller.parent.show_error(event.error.message)
      } else {
        controller.bankNameTarget.textContent = event.bankName
        if(event.branchName) {
          controller.bankNameTarget.textContent += ' ' + event.branchName
        }
        controller.parent.hide_error()
      }
    })
  }

  confirm_mandate_in_stripe() {
     return this.stripe.confirmAuBecsDebitSetup(
      this.options.secret,
      {
        payment_method: {
          au_becs_debit: this.bankAccountTarget,
          billing_details: { 
            name: this.parent.nameOnAccountTarget.value,
            email: this.parent.emailTarget.value
          }
        }
      }
    )
  }
}
