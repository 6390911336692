// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { airbrake } from '../entrypoints/airbrake'
import { Application } from "stimulus"
import { registerControllers } from "stimulus-vite-helpers"

const application = Application.start()
const controllers = import.meta.globEager('./**/*_controller.js')
registerControllers(application, controllers)

// Overwrite the default Stimulus.js error handler so JavaScript errors
// are sent to Airbrake.
const defaultErrorHandler = application.handleError

const airbrakeErrorHandler = (error, message, detail) => {
  defaultErrorHandler(error, message, detail)
  airbrake.notify({
    error: error,
    context: { stimulus_message: message, stimulus_detail: detail }
  })
}

application.handleError = airbrakeErrorHandler
