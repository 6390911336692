import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content']

  change(event) {
    if (!this.hasContentTarget) { return }

    let show = event.currentTarget.checked
    this.contentTarget.classList.toggle('d-none', !show)
  }
}
