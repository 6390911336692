import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container', 'error']

  onUpdateSuccess(event) {
    const resp = event.detail[0]
    this.containerTarget.innerHTML = resp.html
    this.errorTarget.classList.remove('d-flex')
    this.errorTarget.classList.add('d-none')
  }

  onUpdateError(event) {
    this.errorTarget.classList.remove('d-none')
    this.errorTarget.classList.add('d-flex')
  }
}
