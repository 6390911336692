import creditCardType from 'credit-card-type';

export default class DetectCardBrand {
  constructor(brands) {
    // https://github.com/braintree/credit-card-type
    this.creditCardType = creditCardType
    this.brands = brands
  }

  detect(cardNumber) {
    let cardInfo = this.creditCardType(cardNumber)
    if (cardInfo.length < 1) {  // Return undefined for invalid card numbers
      return
    } else {
      return this.brands[cardInfo[0].type]
    }
  }
}
