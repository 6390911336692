import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['institutionNumber', 'transitNumber', 'accountNumber']

  connect() {
    this.application[this.identifier] = this
    this.parent = this.application['stripe-mandates']
    this.stripe = this.parent.stripe
    this.options = this.parent.formTarget.dataset
  }

  confirm_mandate_in_stripe() {
     return this.stripe.confirmAcssDebitSetup(
      this.options.secret,
      {
        payment_method: {
          acss_debit: {
            institution_number: this.institutionNumberTarget.value,
            transit_number: this.transitNumberTarget.value,
            account_number: this.accountNumberTarget.value,
          },
          billing_details: { 
            name: this.parent.nameOnAccountTarget.value,
            email: this.parent.emailTarget.value
          }
        }
      }
    )
  }
}
