import { Controller } from 'stimulus'
import { airbrake } from '../entrypoints/airbrake'

export default class extends Controller {
  static targets = ['checkoutLoader']

  connect() {
    this.params = this.checkoutLoaderTarget.dataset
    const key = this.params.publishableKey
    const stripe_account = this.params.stripeAccount
    this.stripe = Stripe(key, { stripeAccount: stripe_account })
    this.redirect_to_stripe_checkout()
  }

  redirect_to_stripe_checkout() {
    try {
      this.stripe.redirectToCheckout({
        sessionId: this.params.sessionId
      }).then(function (result) {
        if(result.error) {
          this.handle_stripe_redirection_error(result.error.message)
        }
      }.bind(this))
    }catch(e) {
      this.handle_stripe_redirection_error(e.message)
    }
  }

  handle_stripe_redirection_error(error_message) {
    this.show_unable_to_load()
    if(airbrake) {
      airbrake.notify({
        error: 'Stripe Bacs Redirect Failure',
        params: { error: error_message }
      })
    }else{ console.error(error_message) }
  }

  show_unable_to_load() {
    document.querySelector('.unable_to_load').classList.remove('d-none')
  }
}
