import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['state', 'country']

  connect() {
    this.usStates = this.element.dataset.usStates
    this.caStates = this.element.dataset.caStates
  }

  countryChange() {
    this.clearSelect(this.stateTarget)
    if(this.countryTarget.value == 'US') {
      this.addOptionsToSelect(this.stateTarget, this.usStates)
      this.stateTarget.parentElement.classList.remove('d-none')
    }else if(this.countryTarget.value == 'CA') {
      this.addOptionsToSelect(this.stateTarget, this.caStates)
      this.stateTarget.parentElement.classList.remove('d-none')
    }else {
      this.stateTarget.parentElement.classList.add('d-none')
    }
  }

  clearSelect(select_tag) {
    let length = select_tag.options.length - 1
    for(let i = length; i > 0; i--) {
      select_tag.remove(i)
    }
  }

  addOptionsToSelect(select_tag, options) {
    if(typeof(options) == 'string') {
      options = JSON.parse(options)
    }
    for(let i = 0; i < options.length; i++) {
      let opt = document.createElement('option')
      opt.value = options[i][1]
      opt.innerText = options[i][0]
      select_tag.appendChild(opt)
    }
  }
}
